import './components/css/App.css';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import Admin from './components/container/Admin';
import Home from './components/container/Home';
import Scanned from './components/container/Scanned';
import Widget from './components/container/Widget';
import MyStuff from './components/container/MyStuff';
import DetailPage from './components/container/DetailPage';
// import NotFound from './components/NotFound';
import About from './components/container/About';
import OurStory from './components/container/OurStory';
import Login from './components/container/Login';
import SignUp from './components/container/SignUp';
import AcceptTerms from './components/container/AcceptTerms';
import ForgetPassword from './components/container/ForgetPassword';
import ConfirmPassword from './components/container/ConfirmPassword';
import VerificationCode from './components/container/VerificationCode';
import ReactivationCode from './components/container/ReactivationCode';
import VerificationResetCode from './components/container/VerificationResetCode';
import DeleteAccount from './components/container/DeleteAccount';
import DeleteAccountCode from './components/container/DeleteAccountCode';
import Store from './components/Store';
import Chat from './components/container/Chat';
import Subscription from '../src/components/container/Subscription';
import MyQRTags from './components/container/MyQRTags';
import EditProfile from './components/container/EditProfile'
import { React, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { Store as ContextStore, UpdateStore } from './StoreContext';
import { RecieveChannelList } from './services/chat';
import { toast } from 'react-toastify';
import Axios from './config/axios';


const pubnub = new PubNub({
  publishKey: 'pub-c-e402503d-be87-49c1-8de2-c163e902d202',
  subscribeKey: 'sub-c-e45da188-49e0-48e6-9aec-f29c548ec6ed',
  uuid: 'sec-c-N2RhMzE3YTctNTJkMy00Zjk2LWE2NzItYjVjYzhmZGZhZWE4',
});

function App() {
  mixpanel.init('29bc6dc47ac430eeec08c916b09b61e4');
  const { loggedIn, user, channels, messages, firebaseToken } = ContextStore();

  const updateStore = UpdateStore();
  useEffect(() => {
    if (user.id !== undefined) {
      pubnub.setUUID(user.id + 'User');
      RecieveChannelList(user.id);
      let listener = {
        message: (event) => {
          let name = event?.message?.user?.name;
          let email = event?.message?.user?.email;
          let id = event?.message?.user?.id;
          if (window.location.pathname === '/chat' || user?.id === id) {
            return;
          } else {
            toast.success(
              'You have got a new message from ' + (name || email || 'User')
            );
          }
        },
      };
      pubnub.addListener(listener);
      // pubnub.subscribe({ channels: channelList.map((c) => c.id) });

      pubnub.subscribe({ channels: channels.map((c) => c.id) });
      return () => {
        pubnub.removeListener(listener);
        pubnub.unsubscribe({ channels: channels.map((c) => c.id) });
      };
    }
  }, [channels.length, user.id]);

  useEffect(() => {
    if (loggedIn) {
      Axios.get('/common/configuration/').then((resp) => {
        updateStore({ messages: resp.data });
      }).catch(() => { });


      Axios.get(`/users/detail/`).then((response) => {
        if (response && response?.data) {
          localStorage.setItem('user', JSON.stringify(response?.data));
          updateStore({ user: response?.data });
          localStorage.setItem('userData', JSON.stringify(response?.data));
        }
      })
    }
  }, [loggedIn])

  return (
    <PubNubProvider client={pubnub}>
      <BrowserRouter>
        <Routes>
          <Route path='*' element={<Navigate to={'/about'} />} />
          <Route path='uuid/:uid' element={<Scanned />} />
          <Route path='widget/:wid' element={<Widget />} />
          <Route path='/chat' element={<Chat />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/forgetpassword' element={<ForgetPassword />} />
          <Route path='/confirmpassword' element={<ConfirmPassword />} />
          <Route path='/verificationcode' element={<VerificationCode />} />
          <Route path='/verifyresetcode' element={<VerificationResetCode />} />
          <Route path='/reactivationcode' element={<ReactivationCode />} />
          <Route path='/account/delete' element={<DeleteAccount />} />
          <Route path='/account/delete/code' element={<DeleteAccountCode />} />
          <Route path='/acceptterms' element={<AcceptTerms />} />
          {/* localStorage.getItem('i18nextLng') === 'fr' */}
          {/* {localStorage.getItem('amplify-redirected-from-hosted-ui') === 'true' ? (
          <Route path='/' element={<About />} />
        ) : (
          <Route path='/' element={<Login />} />
        )} */}
          {!loggedIn ? (
            <>
              <Route path='/' element={<Login />} />
              <Route path='store' element={<Store />} />
              <Route path='/viewitems' element={<Home />} />

            </>
          ) : (
            <>
              <Route path='admin' element={<Admin />} />
              <Route path='/viewitems' element={<Home />} />
              {/* <Route path='/' element={<Home />} /> */}
              <Route path='store' element={<Store />} />
              <Route path='mystuff' element={<MyStuff />} />
              <Route path='mystuff/:id' element={<DetailPage />} />
              <Route path='/myqrtags' element={<MyQRTags />} />
              <Route path='/subscription' element={<Subscription />} />
              <Route path='/editprofile' element={<EditProfile />} />


              <Route path='/' element={<MyStuff />} />
            </>
          )}
          <Route path='/about' element={<About />} />
          <Route path='/story' element={<OurStory />} />
        </Routes>
        <ToastContainer position='bottom-right' />
      </BrowserRouter>
    </PubNubProvider>
  );
}

export default App;
