/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import Navbar from '../common/Navbar';
import Search from '../../images/search-icon.svg';
import Avatar from '../../images/avatar.png';
import MapIcon from '../../images/map-marker.svg';
import SendIcon from '../../images/send_icon.svg';
import { useEffect, useRef, useState, useMemo } from 'react';
import { usePubNub } from 'pubnub-react';
import Axios from '../../config/axios';
import { Store, UpdateStore } from '../../StoreContext';
import StaticMap from '../common/StaticMap.js';
import moment from 'moment-timezone'
import UnlockFeatureModal from '../common/UnlockFeatureModal'
import BlockUserModal from '../common/BlockUserModal'
import UnBlockUserModal from '../common/UnBlockUserModal'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { toast } from 'react-toastify';


import {
  RecieveChannelList,
  HandleMessageHistory,
  HandleMessageHistoryByID,
  RecieveArchivedChannelList,
} from '../../services/chat';
import mixpanel from 'mixpanel-browser';
import TopNavbar from '../common/TopNavContent.js';
import { BlockRounded, CloseOutlined, CloseRounded } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

function Chat() {

  const { loggedIn, user, channels: channelList, cookies } = Store();

  const { state } = useLocation();
  const updateStore = UpdateStore();
  const pubnub = usePubNub();
  const subscribe = useRef(false);
  const [activeChannel, setActiveChannel] = useState({});
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [sender, setSender] = useState(false) // activeChannel?.users?.find((item) => user?.id !== item?.id);
  const [searchQuery, setSearchQuery] = useState('');
  const [featureModal, setFeatureModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [unBlockModal, setUnBlockModal] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [isUserBeenBlocked, setIsUserBeenBlocked] = useState(false);

  const [archiveAnchorEl, setArchiveAnchorEl] = useState({})
  const [archiveAnchorE2, setArchiveAnchorE2] = useState({})

  const [archivedChats, setArchivedChats] = useState(false)
  const [archivedLists, setArchivedLists] = useState([])

  const moreOptionRefs = useRef([]);

  const handleArchiveClick = (event, index) => {
    if (user && user.user_type === 'looser' && user.subscription_plan === null && user.is_trial === false && user.is_honorary === false) {
      setFeatureModal(true);
      return false;
    }
    setArchiveAnchorEl((prev) => ({
      ...prev,
      [index]: event.currentTarget,
    }));
  }

  const handleMobileArchiveClick = (event, index) => {
    if (user && user.user_type === 'looser' && user.subscription_plan === null && user.is_trial === false && user.is_honorary === false) {
      setFeatureModal(true);
      return false;
    }
    setArchiveAnchorE2((prev) => ({
      ...prev,
      [index]: event.currentTarget,
    }));
  }

  const handleCloseArchiveDropdownList = () => {
    setArchiveAnchorEl({})
    setArchiveAnchorE2({})
  }

  const handleCloseArchiveDropdown = (ch) => {
    setArchiveAnchorEl({})
    setArchiveAnchorE2({})
    Axios.post(`/message/channel/${ch}/archive/`, {}).then((response) => {
      if (response && response.status === 200) {
        toast.success('Channel archived successfully!');
        RecieveChannelList(user?.id);
        RecieveArchivedChannelList(user.id, ({ success, data }) => {
          setArchivedLists(data)
        });
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  const handleCloseUnarchiveDropdown = (ch) => {
    setArchiveAnchorEl({})
    setArchiveAnchorE2({})
    Axios.post(`/message/channel/${ch}/unarchive/`, {}).then((response) => {
      if (response && response.status === 200) {
        toast.success('Channel restored successfully!');
        RecieveChannelList(user?.id);
        RecieveArchivedChannelList(user.id, ({ success, data }) => {
          setArchivedLists(data)
        });
      }
    }).catch((error) => {
      console.log(error)
    });
  }
  const toggleArchivedChats = () => {
    setArchivedChats((prev) => !prev)
  }


  const localTimeZone = (dateTime) => {
    const utcDate = moment.utc(dateTime)
    const localTimezone = moment.tz.guess()
    const localDate = utcDate.tz(localTimezone)
    return localDate.format('YYYY-MM-DD h:mm A')
  }

  const handleMessage = (event) => {
    setMessageList((m) =>
      [
        {
          text: event.message.text,
          extra_message: {
            type: event.message?.type || 'text',
            location: event.message?.location,
          },

          send_by: +event.publisher.split('User')[0],
          send_by_temp: event.publisher.split('User')[0],
          channel: event.channel,
        },
      ].concat(m)
    );
  };

  const sendMessage = (message) => {
    const trimmedMessage = message?.trim();
    if (!trimmedMessage) {
      return;
    }

    if (user && user.user_type === 'looser' && user.subscription_plan === null && user.is_trial === false && user.is_honorary === false) {
      setFeatureModal(true);
      return false;
    }

    if (isUserBlocked) {
      console.log('You have blocked this user');
      return false
    }
    if (isUserBeenBlocked) {
      console.log('You have been blocked by the owner');
      return false
    }
    if (message) {
      pubnub
        .publish({
          channel: [activeChannel.id],
          message: { text: message, user },
        })
        .then((res) => {
          mixpanel.track('Message Sent', {
            status: "Sent"
          });
          setMessage('');
          // recieveChannelList();
        })
        .catch((err) => console.log('send messaeg Error ===> ', err));
    }
    const sendto = activeChannel?.users?.find((item) => user?.id !== item?.id);
    const obj = JSON.stringify({
      send_by: user.id,
      send_to: sendto?.id,
      channel: activeChannel.id,
      text: message,
    });
    Axios.post(`/message/add-message`, obj)
      .then((res) => {
        // handleMessageHistory(activeChannel);
        console.log('addmessages api res =>', res);
      })
      .catch((error) => {
        console.log('addmessages api error', error);
      });
  };
  useEffect(() => {
    pubnub.setUUID(user.id + 'User');
    let listener = { message: handleMessage };
    pubnub.addListener(listener);
    // pubnub.subscribe({ channels: channelList.map((c) => c.id) });
    return () => {

      pubnub.removeListener(listener);
    };
  }, [pubnub]);

  const handleOpen = () => {
    if (user && user.user_type === 'looser' && user.subscription_plan === null && user.is_trial === false && user.is_honorary === false) {
      setFeatureModal(true);
      return false
    }

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    RecieveChannelList(user.id);
    RecieveArchivedChannelList(user.id, ({ success, data }) => {
      setArchivedLists(data)
    });
    mixpanel.track('Chat Page', {
      pageName: "Chat"
    });
  }, []);

  useEffect(() => {
    const uid = localStorage.getItem('uid');
   
    if (state && state.currentChannelId) {
      const channel = FilterData.find((data) => data && data.id === state.currentChannelId);
      if (channel && channel.id) {
        setOpen(true);
        setSender(true);
        setActiveChannel(channel);
        loggedIn
          ? HandleMessageHistory(channel, ({ success, data }) => {
            success && setMessageList(data);
          })
          : HandleMessageHistoryByID(user.id, ({ success, data }) => {
            success && setMessageList(data);
          });

        localStorage.removeItem('uid');
      }
    } else if (uid || cookies?.uid) {
      const channel = FilterData.find((data) => data && data.item.item_id === uid);
      if (channel) {
        setOpen(true);
        setActiveChannel(channel);
        loggedIn
          ? HandleMessageHistory(channel, ({ success, data }) => {
            success && setMessageList(data);
          })
          : HandleMessageHistoryByID(user.id, ({ success, data }) => {
            success && setMessageList(data);
          });

        localStorage.removeItem('uid');
      }
    }
  }, [channelList]);

  // attachment file and searching implementation

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const currentLists = archivedChats ? archivedLists : channelList

  // const FilterData = currentLists.filter((c) => { return c.item })

  const FilterData = useMemo(() => {
    return currentLists.filter((c) => {
      const itemMatches = c.item?.name?.toLowerCase().includes(searchQuery.toLowerCase());

      const messageMatches = c.last_message?.text?.toLowerCase().includes(searchQuery.toLowerCase());

      if (c.users?.length > 0) {
        const userMatches = c.users.some(user =>
          user.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        return userMatches || itemMatches || messageMatches;
      }
      return itemMatches || messageMatches;
    });
  }, [currentLists, searchQuery]);


  // const FilterData = channelList.filter((c) => {
  //   const receivedUser =
  //     user?.email === c?.users[0]?.email ? c?.users[1] : c?.users[0];
  //   return (
  //     receivedUser?.name ||
  //     receivedUser?.email.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  // });

  // const FilterData = channelList.filter((c) => {
  //   const receivedUser =
  //     user?.email === c?.users[0]?.email ? c?.users[1] : c?.users[0];

  //   if (!receivedUser) {
  //     return false;
  //   }

  //   return (
  //     receivedUser?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     receivedUser?.email?.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  // });
  // console.log('user', FilterData)

  const sendLocation = () => {
    if (navigator.geolocation) {

      if (user && user.user_type === 'looser' && user.subscription_plan === null && user.is_trial === false && user.is_honorary === false) {
        setFeatureModal(true);
        return false;
      }

      navigator.geolocation.getCurrentPosition((pos) => {
        const { latitude, longitude } = pos.coords;
        pubnub
          .publish({
            channel: [activeChannel.id],
            message: {
              user,
              type: 'location',
              location: { latitude, longitude },
            },
          })
          .then((res) => {
            // recieveChannelList();
          })
          .catch((err) => console.log('send messaeg Error ===> ', err));

        const sendto = activeChannel?.users?.find(
          (item) => user?.id !== item?.id
        );
        const obj = JSON.stringify({
          send_by: user.id,
          send_to: sendto?.id,
          channel: activeChannel.id,
          extra_message: {
            type: 'location',
            location: { latitude, longitude },
          },
        });
        Axios.post(`/message/add-message`, obj)
          .then((res) => {
            // handleMessageHistory(activeChannel);
            console.log('addmessages api res =>', res);
          })
          .catch((error) => {
            console.log('addmessages api error', error);
          });
      });
    } else {
      console.log('error');
    }
  };

  useEffect(() => {
    if (user && activeChannel && activeChannel?.blocked_by_user && activeChannel?.blocked_by_user?.id === user?.id) {
      setIsUserBlocked(true);
    }
    if (user && activeChannel && activeChannel?.blocked_by_user && activeChannel?.blocked_by_user?.id !== user?.id) {
      setIsUserBeenBlocked(true);
    }
  }, [activeChannel])

  const viewMessage = (item) => {
    if (user && user.user_type === 'looser' && user.subscription_plan === null && user.is_trial === false && user.is_honorary === false) {
      setFeatureModal(true);
    } else {
      setActiveChannel(item);
      setSender(true);
      !loggedIn
        ? HandleMessageHistoryByID(user.id, ({ success, data }) => {
          success && setMessageList(data);
        })
        : HandleMessageHistory(item, ({ success, data }) => {
          success && setMessageList(data);
        });
    }
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <Navbar />
      <TopNavbar />
      <div className='Chat_MainContainer'>
        <div className='Chat_LeftContainer'>

          <h3>Chat</h3>

          <>
            <div className='Chat_LeftContainer_SearchDiv'>
              <input type='text' placeholder='Search' onChange={handleChange} value={searchQuery} />
              <img src={Search} alt="Search Icon" />
            </div>

            {(archivedChats) ? <>
              <div className='d-flex' onClick={toggleArchivedChats} style={{ cursor: "pointer" }}>
                <ArrowBackIcon style={{ marginLeft: "5%", color: '#0a3f74' }} />
                <p style={{ marginLeft: "5%", marginBottom: '0px' }}>Archived</p>
              </div>
              <hr style={{ background: '#D3D3D3' }} />
            </> : <>
              {(archivedLists.length > 0) && <>
                <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }} onClick={toggleArchivedChats}>
                  <IconButton style={{ marginLeft: '12px', color: '#0a3f74' }}>
                    <ArchiveIcon />
                  </IconButton>
                  <Typography variant="body1" style={{ marginLeft: "3%", fontWeight: 500 }}>
                    Archived
                  </Typography>
                </div>

                <hr style={{ background: '#D3D3D3' }} />
              </>}
            </>
            }

            <div className='Chat_MessagesList all-chat-list'>
              {(FilterData && FilterData.length > 0) ?
                <div style={{ width: '100%' }}>
                  {FilterData?.map((item, index) => {
                    const receivedUser =
                      user?.email === item?.users[0]?.email
                        ? item?.users[1]
                        : item?.users[0];
                    const locationData = item?.last_message?.extra_message;
                    const lastMessage = item?.last_message?.text;
                    return (
                      <>
                        <div
                          className={`Chat_MessageBox ${(activeChannel && activeChannel?.id === item?.id) ? 'active' : ''}`}

                        >
                          <div className='Chat_MessageBox_Avatar' onClick={() => {
                            viewMessage(item)
                          }}>
                            <img
                              src={
                                receivedUser?.looser?.profile_picture
                                  ? receivedUser?.looser?.profile_picture
                                  : Avatar
                              }
                            />
                          </div>
                          <div className='Chat_MessageBox_Text' onClick={() => {
                            viewMessage(item)
                          }}>
                            <p className='Chat_MessageBox_Text_P'>
                              {item?.item && item?.item?.name ? (item?.item.user_id !== user?.id ? 'Owner of ' : 'Finder of ') + '' + item?.item?.name : (item?.users && item?.users[0] && item?.users[0].id !== user?.id) ? 'Owner' : 'Finder'}
                            </p>
                            <p style={{ display: 'flex', alignItems: 'center' }}>
                              {(locationData && locationData.type && locationData.type === 'location') ? (
                                <>
                                  <img src={MapIcon} width={15} /> Location
                                </>
                              ) : (lastMessage?.length > 16) ? lastMessage.slice(0, 16) + '...' : lastMessage}
                            </p>
                          </div>
                          <div className='Chat_MessageBox_DateDiv'>
                            <p className='mb-0' style={{ marginRight: '6%' }} onClick={() => {
                              viewMessage(item)
                            }}>
                              {localTimeZone(item.last_message?.created_at)}
                            </p>
                            <div>
                              <IconButton onClick={(e) => { e.stopPropagation(); handleArchiveClick(e, index) }} >
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu
                                anchorEl={archiveAnchorEl[index]}
                                open={Boolean(archiveAnchorEl[index])}
                                onClose={handleCloseArchiveDropdownList}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <MenuItem onClick={(e) => { e.stopPropagation(); archivedChats ? handleCloseUnarchiveDropdown(item?.id) : handleCloseArchiveDropdown(item?.id) }}>
                                  <Typography>{archivedChats ? 'Restore' : 'Archive Chat'}</Typography>
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                        </div>
                        <div className='Chat_MessageBox_Divider' />
                      </>
                    );
                  })}
                </div>
                : <div style={{ width: '100%' }}>
                  <p className='text-center mt-4' style={{ color: 'rgb(221, 221, 221)' }}>No Conversation Yet</p>
                </div>}
            </div>

          </>

        </div>
        <div className='Chat_RightContainer'>
          <div style={{ width: '100%' }}>
            {sender ? (
              <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '90%' }}>
                  <div className='Chat_RightContainer_TitleDiv'>
                    <div className='Chat_RightContainer_ImageDiv'>
                      <img
                        src={
                          sender?.looser?.profile_picture
                            ? sender?.looser?.profile_picture
                            : Avatar
                        }
                      />
                    </div>
                    <div className='Chat_SenderNameDiv'>
                      <p className='Chat_MessageBox_Text_P'>
                        {activeChannel?.item && activeChannel?.item?.name ?
                          (activeChannel?.item.user_id !== user?.id
                            ? 'Owner of '
                            : 'Finder of ') + activeChannel?.item?.name
                          : (activeChannel?.users && activeChannel?.users[0] && activeChannel?.users[0].id !== user?.id) ? 'Owner' : 'Finder'}
                      </p>
                      {/* <p style={{ color: '#818181' }}>Online - Last seen, 2:02pm</p> */}
                    </div>
                  </div>
                  {(user && activeChannel && activeChannel?.item && activeChannel?.item?.user_id === user?.id) &&
                    <div className='mx-4'>
                      {(isUserBlocked) ? <>
                        <Button
                          variant="outlined"
                          sx={{
                            display: 'flex', width: '100%', height: '36px',
                            mx: 'auto', borderColor: 'green', borderRadius: '10px', backgroundColor: '#fff', color: 'green', fontSize: '12px'
                          }}
                          onClick={() => setUnBlockModal(true)}
                        >
                          <BlockRounded sx={{ fontSize: '16px' }} />&nbsp;Unblock
                        </Button>
                      </> : <>
                        <Button
                          variant="outlined"
                          sx={{
                            display: 'flex', width: '100%', height: '36px',
                            mx: 'auto', borderColor: '#D10000', borderRadius: '10px', backgroundColor: '#fff', color: '#D10000', fontSize: '12px'
                          }}
                          onClick={() => setBlockModal(true)}
                        >
                          <BlockRounded sx={{ fontSize: '16px' }} />&nbsp;Block
                        </Button>
                      </>}
                    </div>
                  }
                </div>
                <div className='Chat_ChatDivider' />
                <p style={{ marginTop: '1rem', marginBottom: '1rem', color: '#D10000', textAlign: 'center', width: '90%', fontSize: '1.2rem' }}>Remember, QRTag.it advises against sharing personal information.</p>
              </>
            ) : (
              <>
                <div
                  style={{ fontSize: '3rem', fontWeight: '600', margin: '0 2%' }}
                >
                  Start Chat
                </div>
                <div className='Chat_ChatDivider' />
              </>
            )}
          </div>

          {sender ? (
            <div>
              <div className='Chat_ChatDiv'>
                {messageList.map((item, index) => {

                  const isSender = loggedIn
                    ? item.send_by === user.id
                    : item.send_by_temp === user.id;

                  return (
                    <>
                      {messageList.length > 0 ? (
                        <>
                          {isSender ? (
                            <div className='Chat_SenderChatDiv' >
                              <div className='Chat_SenderChatSubDiv' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: "end" }}>
                                <small className='Chat_MessageTime'>
                                  {moment(item.created_at).tz(moment.tz.guess()).format('hh:mm A')}
                                </small>
                                {
                                  <div
                                    key={`message-${index}`}
                                    className={
                                      item.extra_message?.type === 'location'
                                        ? 'Chat_SenderChatSubDiv_map'
                                        : 'Chat_SenderChatSubDiv_P'
                                    }
                                  >
                                    {item.extra_message?.type === 'location' ? (
                                      <StaticMap {...item.extra_message.location} />
                                    ) : (
                                      item.text
                                    )}

                                  </div>
                                }
                              </div>
                            </div>
                          ) : (

                            <div className='Chat_RevievedChatDiv' style={{ display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start" }}>
                              <small className='Chat_MessageTime'>
                                {moment(item.created_at).tz(moment.tz.guess()).format('hh:mm A')}
                              </small>

                              <div className='Chat_RevievedChatSubDiv_P'>
                                {
                                  <div
                                    key={`message-${index}`}
                                  >
                                    {item.extra_message?.type === 'location' ? (
                                      <StaticMap {...item.extra_message.location} />
                                    ) : (
                                      item.text
                                    )}

                                  </div>
                                }
                              </div>

                            </div>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}
              </div>

              <div>
                <div className='Chat_TypingDivider' />
                {(isUserBlocked) ? <>
                  <div className='Chat_TypingDiv'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <p className='mb-0 px-4' style={{ color: '#818181', width: '90%' }}>You have blocked this user</p>
                  </div>
                </> : (isUserBeenBlocked) ? <>

                  <div className='Chat_TypingDiv'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <p className='mb-0 px-4' style={{ color: '#818181', width: '90%' }}>You have been blocked by this owner</p>
                  </div>
                </> : <>
                  <div className='Chat_TypingDiv'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='Chat_TypingSubDiv'>
                      <button onClick={sendLocation}>
                        <img src={MapIcon} width={28} />
                      </button>

                      <input
                        type='text'
                        placeholder='Type your message here...'
                        value={message}
                        onKeyPress={(e) => {
                          const trimmedMessage = message?.trim();
                          if (!trimmedMessage) {
                            return;
                          } else if (e.key !== 'Enter') return;
                          sendMessage(message);
                        }}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <div
                      className='Chat_SendBtn'
                      onClick={(e) => {
                        e.preventDefault();
                        const trimmedMessage = message?.trim();
                        if (!trimmedMessage) {
                          return;
                        } else {
                          sendMessage(message);
                        }
                      }}
                    >
                      <img src={SendIcon} />
                    </div>
                  </div>
                </>}
              </div>

            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5rem',
                fontWeight: '500',
                width: '90%',
                height: '90vh',
                textAlign: 'center'
              }}
            >
              Select the channel to start chat
            </div>
          )

          }


        </div>
      </div>

      <div className='Chat_MainContainer_Mob'>
        {!open ? (
          <div className='Chat_LeftContainer'>
            <h3>Chat</h3>
            <div className='Chat_LeftContainer_SearchDiv'>
              <input type='text' placeholder='Search' onChange={handleChange} value={searchQuery} />
              <img src={Search} alt="Search Icon" />
            </div>

            {(archivedChats) ? <>
              <div className='d-flex' onClick={toggleArchivedChats} style={{ cursor: "pointer" }}>
                <ArrowBackIcon style={{ marginLeft: "5%" }} />
                <p style={{ marginLeft: "5%", marginBottom: '0px' }}>Archived</p>
              </div>
              <hr style={{ background: '#D3D3D3' }} />
            </> : <>
              {(archivedLists.length > 0) && <>
                <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }} onClick={toggleArchivedChats}>
                  <IconButton style={{ marginLeft: '12px' }}>
                    <ArchiveIcon />
                  </IconButton>
                  <Typography variant="body1" style={{ marginLeft: "3%", fontWeight: 500 }}>
                    Archived
                  </Typography>
                </div>

                <hr style={{ background: '#D3D3D3' }} />
              </>}
            </>
            }

            <div className='Chat_MessagesList'>

              {(FilterData && FilterData.length > 0) ?
                <div>
                  {FilterData?.map((item, index) => {
                    const receivedUser =
                      user?.email === item?.users[0]?.email
                        ? item?.users[1]
                        : item?.users[0];
                    const lastMessage = item?.last_message?.text;
                    const locationData = item?.last_message?.extra_message;

                    return (
                      <>
                        <div
                          className={`Chat_MessageBox ${(activeChannel && activeChannel?.id === item?.id) ? 'active' : ''}`}
                          onClick={() => {

                            if (user && user.user_type === 'looser' && user.subscription_plan === null && user.is_trial === false && user.is_honorary === false) {
                              setFeatureModal(true);
                            } else {
                              setActiveChannel(item);
                              !loggedIn
                                ? HandleMessageHistoryByID(
                                  user.id,
                                  ({ success, data }) => {
                                    success && setMessageList(data);
                                  }
                                )
                                : HandleMessageHistory(
                                  item,
                                  ({ success, data }) => {
                                    success && setMessageList(data);
                                  }
                                );
                            }
                          }}
                        >
                          <div className='Chat_MessageBox_Avatar' style={{ width: '55px', height: '55px' }} onClick={handleOpen}>
                            <img
                              style={{ width: '55px', height: '55px' }}
                              src={
                                receivedUser?.looser?.profile_picture
                                  ? receivedUser?.looser?.profile_picture
                                  : Avatar
                              }
                            />
                          </div>
                          <div className='Chat_MessageBox_Text' onClick={handleOpen}>
                            <p
                              className='Chat_MessageBox_Text_P'
                            >
                              {/* {receivedUser.name || receivedUser.email} */}
                              {item?.item && item?.item?.name ?
                                (item?.item.user_id !== user?.id
                                  ? 'Owner of '
                                  : 'Finder of ') + item?.item?.name
                                : (item?.users && item?.users[0] && item?.users[0].id !== user?.id) ? 'Owner' : 'Finder'}
                            </p>
                           
                            <p style={{ display: 'flex', alignItems: 'center' }} onClick={handleOpen}>
                              {(locationData && locationData.type && locationData.type === 'location') ? <>
                                <img src={MapIcon} width={15} /> Location
                              </> : (lastMessage?.length > 16) ? lastMessage.slice(0, 16) + '...' : lastMessage}
                            </p>
                          </div>

                          <div className='Chat_MessageBox_DateDiv mb-0'>
                            <p style={{ marginRight: '6%' }}>
                              {localTimeZone(item.last_message?.created_at)}
                            </p>
                            {/* <div className='Chat_MessageBox_Status'>
                            <DoubleTick className='doubleTick' fill='#0C80A0' />
                          </div> */}
                            <div>
                              <IconButton onClick={(event) => {
                                event.stopPropagation();
                                handleMobileArchiveClick(event, index);
                              }} >
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu
                                anchorEl={archiveAnchorE2[index]}
                                open={Boolean(archiveAnchorE2[index])}
                                onClose={handleCloseArchiveDropdownList}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <MenuItem onClick={() => archivedChats ? handleCloseUnarchiveDropdown(item?.id) : handleCloseArchiveDropdown(item?.id)}>
                                  <Typography>{archivedChats ? 'Restore' : 'Archive Chat'}</Typography>
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                        </div>
                        <div className='Chat_MessageBox_Divider' />
                      </>
                    );
                  })}
                </div>
                : <div style={{ width: '100%' }}>
                  <p className='text-center mt-4' style={{ color: 'rgb(221, 221, 221)' }}>No Conversation Yet</p>
                </div>}
            </div>
          </div>
        ) : (
          <div className='Chat_RightContainer'>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div className='Chat_RightContainer_TitleDiv'>
                  <div className='Chat_RightContainer_ImageDiv' style={{ width: '55px', height: '55px' }}>
                    <img
                      style={{ width: '55px', height: '55px' }}
                      src={
                        sender?.looser?.profile_picture
                          ? sender?.looser?.profile_picture
                          : Avatar
                      }
                    />
                  </div>
                  <div className='Chat_SenderNameDiv'>
                    <p className='Chat_MessageBox_Text_P mb-0'>
                      {' '}
                      {activeChannel?.item && activeChannel?.item?.name ?
                        (activeChannel?.item.user_id !== user?.id
                          ? 'Owner of '
                          : 'Finder of ') + activeChannel?.item?.name
                        : (activeChannel?.users && activeChannel?.users[0] && activeChannel?.users[0].id !== user?.id) ? 'Owner' : 'Finder'}
                    </p>
                    {/* <p style={{ color: '#818181' }}>
                      Online - Last seen, 2:02pm
                    </p> */}
                  </div>
                </div>
                {(user && activeChannel && activeChannel?.item && activeChannel?.item?.user_id === user?.id) && <>
                  {(isUserBlocked) ? <>
                    <div className='mx-2' style={{ cursor: 'pointer' }} onClick={() => setUnBlockModal(true)}>
                      <BlockRounded sx={{ fontSize: '20px', color: 'green' }} />
                    </div>
                  </> : <>
                    <div className='mx-2' style={{ cursor: 'pointer' }} onClick={() => setBlockModal(true)}>
                      <BlockRounded sx={{ fontSize: '20px', color: '#D10000' }} />
                    </div>
                  </>}
                </>}

                <div style={{ cursor: 'pointer' }} onClick={handleClose}>
                  <CloseOutlined />
                </div>
              </div>

              <div className='Chat_ChatDivider' />

              <p style={{ marginTop: '1rem', marginBottom: '1rem', color: '#D10000', textAlign: "center" }}>Remember, QRTag.it advises against sharing personal information.</p>
            </div>

            <div className='Chat_ChatDiv'>
              {messageList.map((item, index) => {
                const isSender = loggedIn
                  ? item.send_by === user.id
                  : item.send_by_temp === user.id;
                return (
                  <>
                    {messageList.length > 0 ? (
                      <>
                        {isSender ? (
                          <div className='Chat_SenderChatDiv'>
                            <div className='Chat_SenderChatSubDiv' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: "end" }}>
                              <small className='Chat_MessageTime' >
                                {moment(item.created_at).tz(moment.tz.guess()).format('hh:mm A')}
                              </small>
                              {
                                <div
                                  key={`message-${index}`}
                                  className={
                                    item.extra_message?.type === 'location'
                                      ? 'Chat_SenderChatSubDiv_map'
                                      : 'Chat_SenderChatSubDiv_P'
                                  }
                                >
                                  {item.extra_message?.type === 'location' ? (
                                    <StaticMap {...item.extra_message.location} />
                                  ) : (
                                    item.text
                                  )}
                                </div>
                              }
                            </div>
                          </div>
                        ) : (
                          <div className='Chat_RevievedChatDiv' style={{ display: 'flex', flexDirection: 'column', justifyContent: "flex-start", alignItems: "flex-start" }}>
                            <small className='Chat_MessageTime'>
                              {moment(item.created_at).tz(moment.tz.guess()).format('hh:mm A')}
                            </small>
                            <div className='Chat_RevievedChatSubDiv_P'>
                              {
                                <div
                                  key={`message-${index}`}
                                >
                                  {item.extra_message?.type === 'location' ? (
                                    <StaticMap {...item.extra_message.location} />
                                  ) : (
                                    item.text
                                  )}
                                </div>
                              }
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <h1>Start Chat</h1>
                    )}
                  </>
                );
              })}
            </div>
            <div>
              <div className='Chat_TypingDivider' />

              {(isUserBlocked) ? <>
                <div className='Chat_TypingDiv'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <p style={{ color: '#818181', width: '100%', textAlign: 'center', margin: '2%' }}>You have blocked this user</p>
                </div>
              </> : (isUserBeenBlocked) ? <>

                <div className='Chat_TypingDiv'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <p style={{ color: '#818181', width: '100%', textAlign: 'center', margin: '2%' }}>You have been blocked by this user</p>
                </div>
              </> : <>

                <div
                  className='Chat_TypingDiv'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div className='Chat_TypingSubDiv'>
                    {/* <img src={AttachIcon} /> */}
                    <button onClick={sendLocation}>
                      <img src={MapIcon} width={28} />
                    </button>
                    <input
                      type='text'
                      placeholder='Type your message here...'
                      value={message}
                      onKeyPress={(e) => {
                        const trimmedMessage = message?.trim();
                        if (!trimmedMessage) {
                          return;
                        } else if (e.key !== 'Enter') return;
                        sendMessage(message);
                      }}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div
                    className='Chat_SendBtn'
                    onClick={(e) => {
                      e.preventDefault();
                      const trimmedMessage = message?.trim();
                      if (!trimmedMessage) {
                        return;
                      } else {
                        sendMessage(message);
                      }
                    }}
                  >
                    <img src={SendIcon} />
                  </div>
                </div>
              </>}
            </div>
          </div>
        )}
      </div>

      {
        featureModal && (
          <UnlockFeatureModal
            open={featureModal}
            setOpen={setFeatureModal}
          />
        )
      }

      {
        blockModal && (
          <BlockUserModal
            open={blockModal}
            setOpen={setBlockModal}
            currentChannel={activeChannel}
            setIsUserBlocked={setIsUserBlocked}
          />
        )
      }

      {
        unBlockModal && (
          <UnBlockUserModal
            open={unBlockModal}
            setOpen={setUnBlockModal}
            currentChannel={activeChannel}
            setIsUserBlocked={setIsUserBlocked}
          />
        )
      }

    </div >
  );
}

export default Chat;