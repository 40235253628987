import * as React from 'react';
import Box from '@mui/material/Box';
import { Modal, Button, FormControl, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber, validatePhoneNumberLength, parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';
import { GetStates } from '../../services/user';
import { Store as ContextStore, UpdateStore } from '../../StoreContext';
import { RequestHonoraryAccess } from '../../services/user';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -38%)',
    width: 480,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    overflow: 'auto'
};


export default function BasicModal({ open, setOpen, setApplied, setHideButton }) {

    const { user } = ContextStore();
    const updateStore = UpdateStore();
    let [loading, setLoading] = React.useState();
    let [states, setStates] = React.useState([]);

    const [formState, setFormState] = React.useState({
        name: '',
        email: '',
        phone: '',
        description: '',
        state: '',
        city: ''
    });

    const getStateList = () => {
        try {
            GetStates((response) => {
                if (response && response.success) {
                    setStates(response?.data)
                } else {
                    setStates([])
                }
            });
        } catch (error) {
            console.log(false);
        }
    }

    React.useEffect(() => {
        getStateList()

        if (user) {
            setFormState({
                name: user?.name || '',
                email: user?.email || '',
                phone: user?.phone_number ? parsePhoneNumberFromString(user?.phone_number, user?.country_code)?.formatInternational() || '' : '',
                state: user?.looser?.state_code || '',
                city: user?.looser?.city || ''
            });
        }
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const submitForm = () => {
        
        if (formState.name === '') {
            toast.error('Name cannot be empty');
        } else if (formState.phone === '' || typeof formState.phone === 'undefined') {
            toast.error('Phone number cannot be empty');
        } else if (formState.state === '') {
            toast.error('State cannot be empty');
        } else if (formState.city === '') {
            toast.error('City cannot be empty');
        } else if (!formState.description || formState.description === '') {
            toast.error('Please enter why you should be granted honorary access');
        } else {

            const params = {
                name: formState?.name,
                state: formState?.state,
                city: formState?.city,
                phone_number: formState?.phone.replace(/\+/g, ''),
                honorary_access_reason: formState?.description
            }

            try {
                const phone = (formState.phone.startsWith('+') ? formState.phone : `+${formState.phone}`)

                if (validatePhoneNumberLength(phone) === 'TOO_SHORT') {
                    toast.error('Please enter valid phone number');
                    return false
                }

                const parsedNumber = parsePhoneNumber(phone);
                if (parsedNumber) {
                    params.phone_number = parsedNumber?.nationalNumber
                    params.country_code = parsedNumber?.country || 'US'

                    if (parsedNumber?.nationalNumber === "") {
                        toast.error('Please enter valid phone number');
                        return false
                    }
                }
            } catch (e) {
                console.error(e);
            }
            
            setLoading(true);

            try {
                RequestHonoraryAccess(params, (response) => {
                    setLoading(false);
                    if (response && response.success) {
                        setOpen(false)
                        setApplied(true)
                        setHideButton(true)
                        const temp = { ...user }

                        temp['name'] = formState?.name;
                        temp['phone_number'] = params?.phone_number
                        temp['country_code'] = params?.country_code
                        temp['looser']['state_code'] = formState?.state
                        temp['looser']['city'] = formState?.city
                        temp['looser']['access_request'] = "P"

                        localStorage.setItem('user', JSON.stringify(temp));
                        updateStore({ user: temp });
                        localStorage.setItem('userData', JSON.stringify(temp));
                        
                        toast.success(response.data?.message || 'Your details have been successfully submitted. Please wait while your request is reviewed and approved.');
                    } else {
                        if (response && response.error && response.error.data && response.error.data.detail) {
                            toast.error(`${response && response.error && response.error.data.detail}`)
                        } else if (response && response.error && response.error.data && response.error.data.message) {
                            toast.error(response.error.data.message)
                        } else {
                            toast.error(`Failed to request access!`)
                        }
                    }
                });
            } catch (error) {
                setLoading(false);
            }
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                disableEscapeKeyDown
                style={{ overflow: 'auto' }}
            >
                <Box sx={style}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div
                            style={{
                                position: 'absolute',
                                right: '-5%',
                                marginTop: '-5%',
                                border: '2px solid #000',
                                borderRadius: '50%',
                                padding: '3px',
                                backgroundColor: '#fff',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            onClick={closeModal}
                        >
                            <CloseIcon />
                        </div>

                    </div>

                    <div
                        className='text-center'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>

                        <Typography id='modal-modal-title' sx={{ marginTop: '5%' }} style={{ fontWeight: 'bold' }} component='h6' variant='h6'>
                            Honorary access grants you lifetime access to our premium features.
                        </Typography>
                        <Typography id='modal-modal-description' sx={{ mb: 2, mt: 2 }}>
                            Please complete the form below to enroll in the Honorary Access program.
                        </Typography>

                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mt-2'>
                                <label for="Name" style={{ display: "inline-flex" }}>
                                    Name <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail mb-2'
                                    type="text"
                                    id="Name"
                                    name="Name"
                                    placeholder='Enter Name'
                                    value={formState.name}
                                    onChange={(e) => {
                                        setFormState((s) => ({ ...s, name: e.target.value }));
                                    }}
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        padding: '10px'
                                    }}

                                />
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mt-2'>
                                <label for="Email" style={{ display: "inline-flex" }}>
                                    Email <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail mb-2'
                                    type="email"
                                    id="Email"
                                    name="Email"
                                    placeholder='Enter Email'
                                    value={formState.email}
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        padding: '10px'
                                    }}
                                    readOnly
                                />
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mb-1 mt-2'>
                                <label for="Phone" style={{ display: "inline-flex" }}>
                                    Phone Number <span className='text-danger'>*</span>
                                </label>
                                <PhoneInput
                                    international
                                    className='LogIn_InputEmail'
                                    placeholder='Enter Phone Number'
                                    defaultCountry='US'
                                    value={formState.phone && formState.phone.replace(/^(?!\+)/, '+')}
                                    onChange={(phone) => {
                                        setFormState((s) => ({ ...s, phone: phone }));
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mt-2'>
                                <label for="Email" style={{ display: "inline-flex" }}>
                                    State <span className='text-danger'>*</span>
                                </label>
                                <select
                                    className='LogIn_InputEmail '
                                    id="State"
                                    name="State"
                                    placeholder='Select State'
                                    value={formState.state}
                                    onChange={(e) => { setFormState((s) => ({ ...s, state: e.target.value })) }}
                                >
                                    <option value="">State</option>
                                    {(states.map((item) => (
                                        <><option value={item[0]}>{item[1]}</option></>
                                    )))}
                                </select>
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mt-2'>
                                <label for="Email" style={{ display: "inline-flex" }}>
                                    City <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail'
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder='City'
                                    value={formState.city}
                                    onChange={(e) => { setFormState((s) => ({ ...s, city: e.target.value })) }}
                                />
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mb-1 mt-2'>
                                <label for="Phone" style={{ display: "inline-flex" }}>
                                    Why should I be granted honorary access? <span className='text-danger'>*</span>
                                </label>
                                <textarea
                                    className='LogIn_InputEmail' id="description" name="description" rows="3" cols="29" placeholder='Enter Description' style={{ width: '100%', padding: '3% 3%' }}
                                    value={formState.description}
                                    onChange={(e) => {
                                        setFormState((s) => ({ ...s, description: e.target.value }));
                                    }}
                                />
                            </FormControl>
                        </div>

                        <Button
                            variant="outlined"
                            sx={{
                                mt: 2,
                                display: 'block',
                                width: '100%',
                                height: '50px',
                                mx: 'auto',
                                borderColor: '#0a3f74',
                                borderRadius: '30px',
                                backgroundColor: '#0a3f74',
                                color: '#fff',
                                '&:hover': {
                                    borderColor: '#0a3f74',
                                    color: '#fff',
                                    backgroundColor: '#0a3f74',
                                },
                            }}
                            onClick={submitForm}

                        >
                            {!loading ? (
                                <div>Submit</div>
                            ) : (
                                <PulseLoader size={15} color='#ffffff' />
                            )}
                        </Button>
                    </div>

                </Box>
            </Modal>
        </div >
    );
}